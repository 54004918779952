
export default {
  name: 'SectionImageSide',
  props: {
    data: {
      required: true,
      type: Object,
      default: function () {
        return {}
      }
    },
    orderNo: {
      required: true,
      type: Number,
      default: 0
    },
    hash: {
      required: true,
      type: String,
      default: ''
    }
  },
  computed: {
    hasTitle() {
      return this.data.title && this.data.title !== ''
    },
    cdnBase() {
      return this.$store.getters.cdnBase
    },
    withImageOnTheRight() {
      return this.data.imagePosition === 'right'
    },
    testimonialsFormat() {
      return this.data.testimonialsStyle === true
    },
    imageContain() {
      return this.data.imageSize === 'contain'
    },
    assetUrl() {
      if (!this.data.image) return ''
      if (this.data.image.includes('://')) {
        return this.data.image
      }
      return `${this.cdnBase}/${this.data.image}`
    }
  }
}
