
export default {
  name: 'SectionDiscoverArea',
  props: {
    data: {
      required: true,
      type: Object,
      default: function () {
        return {}
      }
    },
    orderNo: {
      required: true,
      type: Number,
      default: 0
    },
    hash: {
      required: true,
      type: String,
      default: ''
    }
  },
  methods: {
    resourceUrl(img) {
      if (img.includes('://')) {
        return img
      }
      return `${this.$store.getters.cdnBase}/${img}`
    }
  }
}
